import axios from "axios";
import qs from "qs";
import {sprintf} from "sprintf-js";

export default class SubCategoriesClient {
  baseUrl = `${process.env.VUE_APP_DOCUMENTS_BASE_URL}`;

  async search({fromRecycleBin, queryStrings} = {}){
    let url = fromRecycleBin ?
      `${this.baseUrl}/deleted-subcategories` : `${this.baseUrl}/subcategories`;
    return axios.get(
      url,
      {
        params: {stage: "staging", ...queryStrings},
        paramsSerializer: (queryStrings) => {
          return qs.stringify(queryStrings, {arrayFormat: 'repeat'});
        }
      }
    );
  }

  async create({data} = {}) {
    return axios.post(
      `${this.baseUrl}/subcategories`,
      data,
      {
        headers: {'x-api-key': process.env.VUE_APP_API_KEY},
      },
    );
  }

  async read({subcategoryId, queryStrings} = {}){
    return axios.get(
      `${this.baseUrl}/subcategories/${subcategoryId}`,
      {
        params: {
          stage: "staging",
          ...queryStrings,
        },
        paramsSerializer: (queryStrings) => {
          return qs.stringify(queryStrings, {arrayFormat: 'repeat'});
        },
      },
    )
  }

  async update({pathParameters, data} = {}){
    let url = `${this.baseUrl}/subcategories/%(id)s`;
    return axios.put(
      `${sprintf(url, pathParameters)}`,
      data,
      {
        headers: { "x-api-key": process.env.VUE_APP_API_KEY },
      },
    );
  }

  async delete({pathParameters} = {}){
    let url = `${this.baseUrl}/subcategories/%(id)s`;
    return axios.delete(
      `${sprintf(url, pathParameters)}`,
      {
        headers: {'x-api-key': process.env.VUE_APP_API_KEY},
      },
    )
  }

  async restore({pathParameters} = {}) {
    let url = `${this.baseUrl}/subcategories/%(id)s/actions/restore`;
    return axios.post(
      `${sprintf(url, pathParameters)}`,
      null,
      {
        headers: {'x-api-key': process.env.VUE_APP_API_KEY},
      });
  }
}


