<template>
  <vx-card :title="this.pageTitle">
    <template #actions>
      <div>
        <vs-button
          v-if="$can('create', 'document')" size="small" color="success" type="border" icon-pack="feather"
          icon="icon-file-plus" @click="createEntry()"
        >
          Nuevo Documento
        </vs-button>
      </div>
    </template>

    <div v-if="$can('list', 'document')" class="vx-row">
      <div class="vx-col md:w-1/4 w-full mt-10">
        <div class="m-3 mt-10">
          <filter-groups :model="queryStrings" :filter-groups="filterGroups" @update="getEntries"/>
          <vs-divider/>
          <div>
            <ul class="centerx">
              <div class="mt-4"><b>Papelera de reciclaje</b></div>
              <li>
                <vs-checkbox v-model="recycleBin" class="mt-4">Si</vs-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="vx-col md:w-3/4 w-full">
        <vs-table
          :max-items="10" pagination search stripe :data="entries" :hoverFlat="true" :total="count"
        >
          <template #thead>
            <vs-th sort-key="name">Nombre</vs-th>
            <vs-th sort-key="title">Título</vs-th>
            <vs-th sort-key="category">Categoría</vs-th>
            <vs-th sort-key="subcategory">Subcategoría</vs-th>
            <vs-th sort-key="institution">Institución</vs-th>
            <vs-th sort-key="visibility">Visibilidad</vs-th>
            <vs-th sort-key="publication_status">Estado</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template v-slot="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td><p class="font-medium">{{record.name || "-"}}</p></vs-td>
              <vs-td><p class="font-medium">{{record.title}}</p></vs-td>
              <vs-td><p class="font-medium">{{record.category.name}}</p></vs-td>
              <vs-td><p class="font-medium">{{record.subcategory.name}}</p></vs-td>
              <vs-td><p class="font-medium capitalize">{{(institutionMapper[record.institution])}}</p></vs-td>
              <vs-td>
                <vx-tooltip :text="record.visibility_status === 'visible' ? 'Visible' : 'Oculto'" style="width: fit-content">
                  <feather-icon
                    :icon="record.visibility_status === 'visible' ? 'EyeIcon' : 'EyeOffIcon'"
                    style="padding: 13px" svgClasses="w-5 h-5"
                  />
                </vx-tooltip>
              </vs-td>
              <vs-td>
                <vs-chip :color="record.publication_status | publication_status_color">
                  {{record.publication_status | publication_status_label}}
                </vs-chip>
              </vs-td>
              <vs-td>
                <div class="flex" v-if="!recycleBin">
                  <vx-tooltip text="Editar">
                    <vs-button
                      :disabled="!$can('retrieve', 'document') || !$can('update', 'document')"
                      radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                      @click.stop="retrieveEntry(record.id)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Publicar">
                    <vs-button
                      :color="record.in_production ? 'dark' : 'success'"
                      :disabled="record.in_production"
                      radius size="large" type="flat" icon-pack="feather"
                      icon="icon-send" @click="confirmCommit(record)" @click.stop
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Eliminar">
                    <vs-button
                      :disabled="!$can('delete', 'document')" radius size="large" color="warning" type="flat"
                      icon-pack="feather" icon="icon-trash-2" @click.stop="confirmDelete(record)"
                    />
                  </vx-tooltip>
                </div>
                <div class="flex" v-else>
                  <vx-tooltip text="Restaurar">
                    <vs-button
                      :disabled="!$can('create', 'document') || !$can('update', 'document')" radius
                      size="large" color="success" type="flat" icon-pack="feather" icon="icon-refresh-cw"
                      @click.stop="confirmRestore(record)"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>

              <template #expand>
                <vs-row>
                  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                    <vs-list class="mr-1 ml-2">
                      <vs-list-item
                        size="large" icon-pack="feather" icon="icon-link" title="URL Portal Privado"
                        :subtitle="entry_url('staging', record)"
                      >
                        <div class="flex">
                          <vs-tooltip text="Ir">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-external-link"
                              @click="goToEntryPage(record, 'staging')"
                            />
                          </vs-tooltip>
                          <vs-tooltip text="Copiar">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-copy"
                              @click="copyEntryURLToClipboard(record, 'staging')"
                            />
                          </vs-tooltip>
                        </div>
                      </vs-list-item>
                      <vs-list-item
                        size="large" icon-pack="feather" icon="icon-link" title="URL Portal Público"
                        :subtitle="entry_url('public', record)"
                      >
                        <div class="flex">
                          <vs-tooltip text="Ir">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-external-link"
                              @click="goToEntryPage(record, 'public')"
                            />
                          </vs-tooltip>
                          <vs-tooltip text="Copiar">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-copy"
                              @click="copyEntryURLToClipboard(record, 'public')"
                            />
                          </vs-tooltip>
                        </div>
                      </vs-list-item>
                      <vs-list-item
                        v-if="record.source_type!=='link'"
                        size="large" icon-pack="feather" icon="icon-link" title="URL S3"
                        :subtitle="record.media.file.url"
                      >
                        <div class="flex">
                          <vs-tooltip text="Ir">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-external-link"
                              @click="goToEntryPage(record, 's3')"
                            />
                          </vs-tooltip>
                          <vs-tooltip text="Copiar">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-copy"
                              @click="copyEntryURLToClipboard(record, 's3')"
                            />
                          </vs-tooltip>
                        </div>
                      </vs-list-item>
                    </vs-list>
                  </vs-col>
                </vs-row>
              </template>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import DocumentsClient from "@/utils/documentsClient";
  import FilterGroups from "@/components/filter-groups/FilterGroups";
  import Notifier from "@/utils/notification";
  import * as notificationMixins from '@/utils/notificationMixins'
  import CategoriesClient from "@/utils/categoriesClient";
  import SubCategoriesClient from "@/utils/subcategoriesClient";
  import {cloneDeep} from "lodash";
  import CoreClient from "@/utils/coreClient";

  let notifier = new Notifier();
  const categoriesClient = new CategoriesClient();
  const documentsClient = new DocumentsClient();
  const subcategoriesClient = new SubCategoriesClient();
  const institutionsClient = new CoreClient("institutions");

  export default {
    components: {
      FilterGroups
    },

    data() {
      return {
        moduleSlug: "documentos",
        pageTitle: "Documentos",
        count: 0,
        entries: [],
        filterGroups: [
          {
            name: "institution",
            label: "Institución",
            filters: [],
          },
          {
            name: "category_id",
            label: "Categoría",
            filters: [],
          },
          {
            name: "publication_status",
            label: "Estado",
            filters: [
              {value: "published", label: "Publicado"},
              {value: "historic", label: "Histórico"},
              {value: "pending", label: "Pendiente"},
            ],
          },
          {
            name: "source_type",
            label: "Externo",
            filters: [
              {value: "document", label: "Si"},
              {value: "link", label: "No"},
            ],
          },
          {
            name: "relevance",
            label: "Destacado",
            filters: [
              {value: "featured", label: "Si"},
              {value: "normal", label: "No"},
            ],
          },
          {
            name: "visibility_status",
            label: "Visibilidad",
            filters: [
              {value: "visible", label: "Visible"},
              {value: "hidden", label: "Oculto"},
            ],
          }
        ],
        page_size: 0,
        queryStrings: {},
        recycleBin: false,
      };
    },

    watch: {
      async recycleBin() {
        await this.getEntries();
      }
    },

    created() {
      this.$vs.loading();
      this.getEntries(false)
        .then(() => {
          this.getInstitutions()
            .then(() => {
              this.getCategories(false)
                .then(() => {
                  this.$vs.loading.close();
                })
            })
        })
    },

    computed: {
      institutionMapper() {
        return {
          bank: "banco",
          insurances: "seguros",
          companies: "empresas",
        }
      }
    },

    methods: {
      createEntry() {
        this.$router.push({name: "create-document"});
      },

      async deleteEntry(entryId) {
        if (entryId !== null && entryId !== undefined && entryId !== "") {
          try{
            this.$vs.loading();
            await documentsClient.delete({pathParameters: {entryId: entryId}})
            await notifier.notification("deleted");
            this.$vs.loading.close();
            await this.getEntries();
          } catch (_) {
            this.$vs.loading.close();
            await notifier.alertMessage("error");
          }
        }
      },

      retrieveEntry(entryId) {
        this.$router.push({name: "read-document", params: {id: entryId}});
      },

      confirmCommit(entry) {
        notificationMixins.CommitEntryNotification.fire()
          .then(async (result) => {
            if (result.value && entry.id) {
              try{
                this.$vs.loading();
                await documentsClient.commit({pathParameters: {entryId: entry.id}});
                await notifier.notification("committed");
                this.$vs.loading.close();
                await this.getEntries();
              } catch (_) {
                this.$vs.loading.close();
                await notifier.alertMessage("error");
              }
            }
          });
      },

      confirmDelete(entry) {
        notifier.deleteMessage().then(async (result) => {
          if (result.value){
            await this.deleteEntry(entry.id);
          }
        })
      },

      confirmRestore(entry) {
        notificationMixins.RestoreEntryNotification.fire()
          .then(async (result) => {
            if (result.value && entry.id) {
              try{
                this.$vs.loading();
                await documentsClient.restore({pathParameters: {id: entry.id}, data: null});

                await notifier.notification("restored");
                this.$vs.loading.close();
                await this.getEntries();
              } catch (_) {
                this.$vs.loading.close();
                await notifier.alertMessage("error");
              }
            }
          })
      },

      async getInstitutions() {
        this.$vs.loading();
        await institutionsClient.all()
          .then(response => {
            response.data.forEach(element => {
              this.filterGroups[0].filters.push({
                value: element.id,
                label: element.name
              });
            });
            this.$vs.loading.close();
          })
          .catch(error => {
            this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getEntries(showLoading = true) {
        this.entries = [];
        try{
          if (showLoading) this.$vs.loading();
          let visibility_status = cloneDeep(this.queryStrings.visibility_status);
          if(!visibility_status || visibility_status.length ===0){
            visibility_status = ["visible", "hidden"]
          }
          const response = await documentsClient.search({
            recycleBin: this.recycleBin,
            queryStrings: {
              ...this.queryStrings,
              visibility_status: visibility_status,
            }
          });
          this.entries = response.data.results;
          this.count = response.data.count;
          this.page_size = response.data.page_size;

          if (showLoading) this.$vs.loading.close();
        } catch (_) {
          if (showLoading) this.$vs.loading.close();
          await notifier.notification("error");
        }
      },

      async getCategories(showLoading = true) {
        try {
          if (showLoading) this.$vs.loading();
          const response = await categoriesClient.search();

          this.filterGroups[1].filters = response.data.results.map((element) => {
            return {
              value: element.id,
              label: element.name,
            }
          });
          if (showLoading) this.$vs.loading.close();
        } catch (_) {
          if (showLoading) this.$vs.loading.close();
          await notifier.notification("error");
        }
      },

      entry_url(stage, entry){
        if(stage === "s3" || entry.source_type === "link"){
          return entry.media.file.url;
        }

        let baseURL = stage === "staging"
            ? process.env.VUE_APP_STAGING_SITE_BASE_URL
            : process.env.VUE_APP_PUBLIC_SITE_BASE_URL;

        return `${baseURL}/${this.institutionMapper[entry.institution]}/${this.moduleSlug}/${entry.slug}`;
      },

      goToEntryPage(entry, stage) {
        window.open(this.entry_url(stage, entry));
      },

      copyEntryURLToClipboard(entry, stage){
        navigator.clipboard.writeText(this.entry_url(stage, entry));
      },
    },
  }
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>
