import axios from 'axios';

let qs = require('qs');
const sprintf = require('sprintf-js').sprintf;

export default class DocumentsClient {
  baseUrl = `${process.env.VUE_APP_DOCUMENTS_BASE_URL}`

  // documents
  async create({data} = {}) {
    return axios({
      url: `${this.baseUrl}/`,
      method: "post",
      data: data,
      headers: {'x-api-key': process.env.VUE_APP_API_KEY},
    });
  }

  async read({pathParameters, queryStrings} = {}) {
    let url = `${this.baseUrl}/%(id)s`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: "get",
      params: {stage: "staging", ...queryStrings},
      paramsSerializer: function(queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'});
      }
    });
  }

  async update({pathParameters, data} = {}) {
    let url = `${this.baseUrl}/%(id)s`
    return axios.put(
      `${sprintf(url, pathParameters)}`,
      data,
      {
        headers: {'x-api-key': process.env.VUE_APP_API_KEY}
      });
  }

  async search({recycleBin, queryStrings} = {}) {
    let url = recycleBin ? `${this.baseUrl}/deleted-documents/` : `${this.baseUrl}/`;
    return axios({
      url: url,
      method: 'get',
      params: {
        stage: "staging",
        page_size: "all",
        ...queryStrings
      },
      paramsSerializer: function(queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'})
      },
      ...(recycleBin && {headers: {"x-api-key": process.env.VUE_APP_API_KEY}}),
    });
  }

  async commit({pathParameters} = {}) {
    let url = `${this.baseUrl}/%(entryId)s/actions/commit`
    return axios.post(
      `${sprintf(url, pathParameters)}`,
      null,
      {
        headers: {'x-api-key': process.env.VUE_APP_API_KEY},
      },
    );
  }

  async delete({pathParameters} = {}) {
    let url = `${this.baseUrl}/%(entryId)s`;
    return axios.delete(
      `${sprintf(url, pathParameters)}`,
      {
        headers: {'x-api-key': process.env.VUE_APP_API_KEY}
      });
  }

  async restore({pathParameters, data} = {}) {
    let url = `${this.baseUrl}/%(id)s/actions/restore`;
    return axios.post(
      `${sprintf(url, pathParameters)}`,
      data,
      {
        headers: {'x-api-key': process.env.VUE_APP_API_KEY},
      }
    );
  }
}
