import axios from "axios";
import qs from "qs";
import { sprintf } from "sprintf-js";

export default class CategoriesClient {
  baseUrl = `${process.env.VUE_APP_DOCUMENTS_BASE_URL}`

  async create({data} = {}) {
    return axios.post(
      `${this.baseUrl}/categories`,
      data,
      {
        headers: {'x-api-key': process.env.VUE_APP_API_KEY},
      },
    );
  }

  async read({pathParameters, queryStrings} = {}) {
    let url = `${this.baseUrl}/categories/%(id)s`;
    return axios.get(
      `${sprintf(url, pathParameters)}`,
      {
        params: {
          stage: "staging",
          ...queryStrings,
        },
      });
  }

  async update({pathParameters, data} = {}){
    let url = `${this.baseUrl}/categories/%(id)s`;
    return axios.put(
      `${sprintf(url, pathParameters)}`,
      data,
      {
          headers: { "x-api-key": process.env.VUE_APP_API_KEY },
      },
    );
  }

  async delete({pathParameters} = {}) {
    let url = `${this.baseUrl}/categories/%(id)s`
    return axios.delete(
      `${sprintf(url, pathParameters)}`,
      {
        headers: {'x-api-key': process.env.VUE_APP_API_KEY},
      },
    );
  }

  async search({fromRecycleBin, queryStrings} = {}) {
    let url = fromRecycleBin ?
      `${this.baseUrl}/deleted-categories` : `${this.baseUrl}/categories`;
    return axios({
      url: url,
      method: "get",
      params: queryStrings,
      paramsSerializer: function(queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'});
      },
    });
  }

  async restore({pathParameters} = {}) {
    let url = `${this.baseUrl}/categories/%(id)s/actions/restore`;
    return axios.post(
      `${sprintf(url, pathParameters)}`,
      null,
      {
        headers: {'x-api-key': process.env.VUE_APP_API_KEY},
      },
    );
  }
}
