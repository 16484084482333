<template>
  <div>
    <ul class="centerx">
      <div v-for="(filterGroup, index) in filterGroups" :key="index">
        <vs-divider v-if="index !== 0 && index !== filterGroups.length"/>
        <div class="mt-4"><b>{{ filterGroup.label }}</b></div>
        <li v-for="(filter, index) in filterGroup.filters" :key="index">
          <vs-checkbox
              class="mt-4" @change.native="changeFilter(filter.value, filterGroup.name)" :vs-value="filter.value"
          >{{ filter.label }}
          </vs-checkbox>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
  export default {
    props: [
      "model",
      "filterGroups"
    ],

    mounted() {
      for (let x of this.filterGroups) {
        this.model[x.name] = [];
      }
    },

    methods: {
      changeFilter(value, field) {
        if (this.model[field].includes(value)) {
          this.model[field] = this.model[field].filter(item => item !== value)
        } else {
          this.model[field].push(value)
        }
        this.$emit("update", this.model)
      }
    }
  };
</script>